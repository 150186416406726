import { Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoginService } from '@ogreg/ogreg-login';
import {
  ObINavigationLink,
  ObMasterLayoutConfig,
  ObMasterLayoutModule,
  ObButtonDirective,
  ObExternalLinkDirective
} from '@oblique/oblique';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatCardSubtitle, MatCardTitle } from "@angular/material/card";
import {environment} from "../environments/environment";
import { MonitoringService } from "./monitoring/monitoring.service";

@Component({
    selector: 'ogp-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [ObMasterLayoutModule, NgIf, MatButton, ObButtonDirective, MatIcon, TranslateModule, ObExternalLinkDirective, MatCardSubtitle, MatCardTitle]
})
export class AppComponent {
    title = 'title';
    navigation: ObINavigationLink[] = [
        {
            url: 'home',
            label: this.translate.instant('homePage.title')
        },
        {
            url: 'person-register',
            label: this.translate.instant('personRegister.title')
        },
        {
            url: 'volition',
            label: this.translate.instant('volitions.title')
        },
        {
            url: 'faq',
            label: this.translate.instant('faq.title')
        },
        {
            url: environment.eiamLoginUrl,
            label: 'EIAM-Login'
        }
    ];

    constructor(
        private readonly translate: TranslateService,
        private readonly loginService: LoginService,
        private readonly router: Router,
        private readonly config: ObMasterLayoutConfig,
        private readonly monitoringService: MonitoringService
    ) {
        config.locale.locales = ['de', 'en'];
        config.layout.hasMaxWidth = true;
        config.header.serviceNavigation.displayProfile = true;
        if(environment.debugMode) {
            this.navigation.push({
                url: 'debug',
                label: 'DEBUG'
            })
            this.navigation.push({
                url: 'debug-login',
                label: 'Debug-Login'
            })
        }

        this.loginService.checkLoggedIn()
    }

    isLoggedIn() {
        return this.loginService.isLoggedIn();
    }

    async logOut() {
        this.loginService.logOut();
        await this.router.navigate([``]);
    }
}
