import { Injectable } from '@angular/core';
import { VolitionDto } from '../generated/model/models';

@Injectable({
  providedIn: 'root'
})
export class VolitionService {
  constructor() {}

  resetBodyPartsPerType(
    donorType: string | undefined,
    selectedBodyParts: string[] | undefined
  ) {
    if (donorType === 'FULL_DONOR' || donorType === 'NON_DONOR') {
      return [];
    }
    return selectedBodyParts;
  }

  buildNewVolition(
    volitionDto: VolitionDto | undefined,
    selectedDonorType: string | undefined,
    selectedBodyParts: string[] | undefined
  ): VolitionDto {
    const bodyPartIds = this.resetBodyPartsPerType(
      selectedDonorType,
      selectedBodyParts
    );
    const newVolition = {
      id: volitionDto?.id,
      donorType: selectedDonorType,
      bodyPartIds,
      acceptedLegalText: true
    };
    // @ts-expect-error refactor it
    return newVolition;
  }
}
