import { LoginService } from "@ogreg/ogreg-login";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";

@Component({
  selector: "ogp-debug-login",
  templateUrl: "./debug-login.component.html",
  standalone: true,
  imports: [
    FormsModule,
    MatInput
  ],
  styleUrls: ["./debug-login.component.scss"]
})
export class DebugLoginComponent {

  id = 'CH1234567'
  firstName = 'Vorname'
  lastName = 'Nachname'
  birthDate = '1970-01-30'
  nationality = 'CH'
  placeOfBirth = 'Bern'
  homeTown = 'Bern'
  socialSecurityNumber = '756.1234.567'
  gender = 'M'

  constructor(private loginService: LoginService, private http: HttpClient) {}

  public login() {
    this.http.post(environment.personRegisterUrl + 'debug/log-me-in', {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      birthDate: this.birthDate,
      nationality: this.nationality,
      placeOfBirth: this.placeOfBirth,
      homeTown: this.homeTown,
      socialSecurityNumber: this.socialSecurityNumber,
      gender: this.gender
    }).subscribe(obs => {
      this.loginService.logIn();
    });
  }
}