import { LoginService } from "@ogreg/ogreg-login";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";

@Component({
  selector: "ogp-debug-login",
  templateUrl: "./debug-login.component.html",
  standalone: true,
  imports: [
    FormsModule,
    MatInput
  ],
  styleUrls: ["./debug-login.component.scss"]
})
export class DebugLoginComponent {

  id = 'CH1234567'
  firstName = 'Vorname'
  lastName = 'Nachname'
  birthDate = '1970-01-30'
  nationality = 'CH'
  placeOfBirth = 'Bern'
  homeTown = 'Bern'
  socialSecurityNumber = '756.1234.567'
  gender = 'M'

  constructor(private loginService: LoginService, private http: HttpClient) {}

  public login() {
    window.sessionStorage.clear();
    window.sessionStorage.setItem("access_token", "eyJhbGciOiJub25lIn0." + btoa(JSON.stringify(
      { "sub": this.id,
        "given_name": this.firstName,
        "family_name": this.lastName,
        "birthdate": this.birthDate,
        "nationality": this.nationality,
        "place_of_birth": this.placeOfBirth,
        "home_town": this.homeTown,
        "social_security_number": this.socialSecurityNumber,
        "gender": this.gender
      })) + ".");
    window.sessionStorage.setItem("access_token_stored_at", "1724682022549")
    window.sessionStorage.setItem("expires_at", "1924682022549")
    this.loginService.logIn()
  }
}