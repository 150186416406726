import { ApplicationInsights, DistributedTracingModes } from "@microsoft/applicationinsights-web";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable(
  { providedIn: 'root' }
)
export class MonitoringService {
  appInsights?: ApplicationInsights;
  constructor() {
    if(!environment?.appInsights?.instrumentationKey)
      return;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route change
        appId: "test-frontend",
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelope) => {
      if(envelope.tags) {
        envelope.tags["ai.cloud.role"] = "frontend";
      }
    });
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights?.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights?.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights?.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights?.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights?.trackTrace({ message: message}, properties);
  }
}