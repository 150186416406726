import { PersonsService } from '../services/http-services/persons.service';
import { map } from 'rxjs';
import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { PersonDto } from "@ogreg/shared";

export const isRegisteredGuard: CanActivateFn = () => {
  return inject(PersonsService)
    .getPersonDto()
    .pipe(
      map((person: PersonDto) => {
        return !!person?.id;
      })
    );
};
