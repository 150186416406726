import { Component } from '@angular/core';
import { VolitionDto } from '../generated/model/models';
import { VolitionsService } from '../services/http-services/volitions.service';
import { Observable, switchMap, tap } from 'rxjs';
import { ObNotificationService } from '@oblique/oblique';
import { TranslatePipe } from '@ngx-translate/core';
import { VolitionFormComponent } from './volition-form/volition-form.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'ogp-volition',
  templateUrl: './volition.component.html',
  styleUrls: ['./volition.component.scss'],
  standalone: true,
  imports: [NgIf, VolitionFormComponent, AsyncPipe]
})
export class VolitionComponent {
  volition$: Observable<VolitionDto>;
  donorTypes: string[] = ['FULL_DONOR', 'PARTIAL_DONOR', 'NON_DONOR'];
  bodyParts$ = this.volitionsService.getBodyParts();

  constructor(
    private readonly volitionsService: VolitionsService,
    private readonly notificationService: ObNotificationService,
    private readonly translationPipe: TranslatePipe
  ) {
    this.volition$ = this.getVolition();
  }

  onVolitionChange(volitionDto: VolitionDto) {
    if (!volitionDto.id) {
      this.volition$ = this.volitionsService.createVolition(volitionDto).pipe(
        switchMap(() => this.getVolition()),
        tap((volitionDto: VolitionDto) => {
          if (volitionDto?.id) {
            this.notificationService.success({
                message: this.translationPipe.transform('volitions.volitionCreatedNotification'),
                timeout: 20000
              }
            );
          }
        })
      );
    } else {
      // @ts-expect-error refactor it
      this.volition$ = this.volitionsService.editVolition(volitionDto).pipe(
        switchMap(() => this.getVolition()),
        tap((volitionDto: VolitionDto) => {
          if (volitionDto?.id) {
            this.notificationService.success({
                message: this.translationPipe.transform('volitions.volitionEditedNotification'),
                timeout: 20000
              }
            );
          }
        })
      );
    }
  }

  getVolition(): Observable<VolitionDto> {
    return this.volitionsService.getVolition();
  }
}
