import { Component, ViewEncapsulation } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import { ObAlertComponent, ObNotificationService } from "@oblique/oblique";
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable, MatTableDataSource
} from "@angular/material/table";
import { DebugMailVerificationInformationDto, PersonDto } from "@ogreg/shared";
import { MatButton } from "@angular/material/button";
import {
  VolitionDto
} from "../generated/model/models";
import { LoginService } from "@ogreg/ogreg-login";
import { NgIf } from "@angular/common";

@Component({
  selector: 'ogp-debug',
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatRow,
    MatButton,
    MatHeaderRowDef,
    MatRowDef,
    ObAlertComponent,
    NgIf
  ],
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DebugComponent {

  personDataSource = new MatTableDataSource<PersonDto>();
  verificationDataSource = new MatTableDataSource<DebugMailVerificationInformationDto>();
  volitionsDataSource = new MatTableDataSource<VolitionDto>();

  allPersons: PersonDto[] = [];
  allVerifications: DebugMailVerificationInformationDto[] = [];
  allVolitions: VolitionDto[] = [];

  displayedColumnsPerson = ['id', 'firstName', 'lastName', 'birthDate', 'nationality', 'placeOfBirth', 'homeTown', 'socialSecurityNumber', 'email', 'telephoneNumber', 'mobileNumber', 'acceptedLegalText'];
  displayedColumnsVerification = ['forPerson', 'forMail', 'expirationTimestamp', 'code'];
  displayedColumnsVolitions = ['id', 'donorType', 'bodyPartIds', 'acceptedLegalText', 'timeGiven'];
  displayedColumnsVolitionAccess = ['id', 'volitionId', 'accessedBy', 'accessedAt', 'accessedByType', 'toBeDeletedAt'];
  displayedColumnsVolitionChange = ['volitionId', 'changedAt', 'toBeDeletedAt'];

  constructor(private http: HttpClient, public loginService: LoginService, private notificationService: ObNotificationService) {
    this.refreshData();
  }
  deleteUserDataPersonsRegister() {
    this.http.post<{success?: boolean}>(environment.personRegisterUrl + "debug/deleteme", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten des Nutzers aus dem Personenregister gelöscht")
        this.refreshData();
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    })
  }

  deleteUserDataVolitionDb() {
    this.http.post<{success?: boolean}>(environment.volitionUrl + "debug/deleteme", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten des Nutzers aus der Volition-Db gelöscht")
        this.refreshData();
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    })
  }

  deleteEverythingPersonRegister() {
    this.http.post<{success?: boolean}>(environment.personRegisterUrl + "debug/delete-everything", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten aus dem Personenregister gelöscht")
        this.refreshData();
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    });
  }

  deleteEverythingVolitionDb() {
    this.http.post<{success?: boolean}>(environment.volitionUrl + "debug/delete-everything", null).subscribe(res => {
      if(res?.success) {
        this.notificationService.success("Alle Daten aus der Volition-Db gelöscht")
        this.refreshData();
      } else {
        this.notificationService.error(JSON.stringify(res));
      }
    });
  }

  deleteAllData() {
    this.deleteEverythingPersonRegister();
    this.deleteEverythingVolitionDb();
  }

  deleteAllUserData() {
    this.deleteUserDataPersonsRegister();
    this.deleteUserDataVolitionDb();
  }



  fetchAllPersons() {
    this.http.get<PersonDto[]>(environment.personRegisterUrl + "debug/all-persons").subscribe((res) => {
      this.allPersons = res;
      this.personDataSource.data = this.allPersons;
      console.log(this.allPersons)
    })
  }

  fetchAllMailVerifications() {
    this.http.get<DebugMailVerificationInformationDto[]>(environment.personRegisterUrl + "debug/all-mail-verifications").subscribe((res) => {
      this.allVerifications = res;
      this.verificationDataSource.data = this.allVerifications;
    })
  }

  fetchAllVolitions() {
    this.http.get<VolitionDto[]>(environment.volitionUrl + "debug/all-volitions").subscribe((res) => {
      this.allVolitions = res;
      this.volitionsDataSource.data = this.allVolitions;
    })
  }


  refreshData() {
    this.fetchAllPersons();
    this.fetchAllMailVerifications();
    this.fetchAllVolitions();
  }
}
