import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import { HttpClient } from '@angular/common/http';
import {VolitionDto} from '../../generated/model/models';
import { BodyPartDto, VolitionCompletedDto } from "../../generated/model/modelsQuery";

@Injectable({
  providedIn: 'root'
})
export class VolitionsService {
  model = 'volitions';

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService
  ) {}

  getVolition(): Observable<VolitionDto> {
    return this.http.get<VolitionDto>(`${this.env.ogVolitionReadUrl}${this.model}`);
  }

  createVolition(volitionDto: VolitionDto): Observable<void> {
    return this.http.post<void>(
      `${this.env.ogVolitionUrl}${this.model}`,
      { volitionDto: volitionDto }
    );
  }

  editVolition(volitionDto: {
    bodyPartIds: (string | undefined)[];
    donorType: string | undefined;
  }): Observable<void> {
    return this.http.put<void>(
      `${this.env.ogVolitionUrl}${this.model}`,
      { volitionDto: volitionDto }
    );
  }

  getBodyParts(): Observable<BodyPartDto[]> {
    return this.http.get<BodyPartDto[]>(`${this.env.ogVolitionReadUrl}body-parts`);
  }

  isVolitionCompleted(): Observable<VolitionCompletedDto> {
    return this.http.get<VolitionCompletedDto>(
        `${this.env.ogVolitionReadUrl}${this.model}/completed`
    )
  }
}
