<mat-card class="personal-data">
  <mat-card-header>
    <mat-card-title><h2>{{ "personRegister.personalData.title" | translate }}</h2></mat-card-title>
    <mat-card-subtitle>
      {{ "personRegister.personalData.subtitle" | translate }}
      «<a href="person-register#contact-details">{{ "personRegister.contactDetails.title" | translate }}</a>».
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="eIamPerson">
    <div class="container e-iam-person disabled-color">
      <div class="row">
        <mat-label class="col-sm-6"
                   data-cy="lastName">
          {{ "personRegister.contactDetails.person.lastName" | translate }}
          <br>
          {{ eIamPerson.lastName }}
        </mat-label>
        <mat-label class="col-sm-6"
                   data-cy="firstName">
          {{ "personRegister.contactDetails.person.firstName" | translate }}
          <br>
          {{ eIamPerson.firstName }}
        </mat-label>
      </div>
      <div class="row">
        <mat-label class="col-sm-6"
                   data-cy="birthDate">
          {{ "personRegister.contactDetails.person.birthDay" | translate }}
          <br>
          {{ eIamPerson.birthDate }}
        </mat-label>
        <mat-label class="col-sm-6"
                   data-cy="placeOfBirth">
          {{ "personRegister.contactDetails.person.placeOfBirth" | translate }}
          <br>
          {{ eIamPerson.placeOfBirth }}
        </mat-label>
      </div>
      <div class="row">
        <mat-label class="col-sm-6"
                   data-cy="homeTown">
          {{ "personRegister.contactDetails.person.homeTown" | translate }}
          <br>
          {{ eIamPerson.homeTown }}
        </mat-label>
        <mat-label class="col-sm-6"
                   data-cy="nationality">
          {{ "personRegister.contactDetails.person.nationality" | translate }}
          <br>
          {{ eIamPerson.nationality }}
        </mat-label>
      </div>
      <div class="row">
        <mat-label class="col-sm-6"
                   data-cy="socialSecurityNumber">
          {{ "personRegister.contactDetails.person.socialSecurityNumber" | translate }}
          <br>
          {{ eIamPerson.socialSecurityNumber }}
        </mat-label>
      </div>
    </div>
    <p style="padding-top: 40px;">{{ "personRegister.personalData.changeDataText" | translate }}
      <br />
      <a href="https://www.bit.admin.ch/bit/de/home.html" icon="right">
        {{ "personRegister.personalData.personalDataLinkText" | translate }}
      </a>
    </p>
  </mat-card-content>
</mat-card>
