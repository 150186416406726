import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VolitionDto } from '../../generated/model/models';
import { VolitionService } from '../volition.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { ObCheckboxDirective, ObButtonDirective } from '@oblique/oblique';
import { MatError } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCard } from '@angular/material/card';
import { BodyPartDto } from "../../generated/model/modelsQuery";

@Component({
    selector: 'ogp-volition-form',
    templateUrl: './volition-form.component.html',
    styleUrls: ['./volition-form.component.scss'],
    standalone: true,
    imports: [MatCard, FormsModule, ReactiveFormsModule, MatRadioGroup, NgFor, MatRadioButton, NgIf, MatError, ObCheckboxDirective, MatCheckbox, MatButton, ObButtonDirective, TranslateModule]
})
export class VolitionFormComponent implements OnInit {
  @Input() volition: VolitionDto | undefined = undefined;
  @Input() donorTypes: string[] | undefined;
  @Input() bodyParts?: BodyPartDto[];
  @Output() readonly volitionChange: EventEmitter<VolitionDto> =
    new EventEmitter<VolitionDto>();
  showNoBodyPartsSelectedErrorMessage = false;
  formGroup: FormGroup;

  constructor(
    private readonly volitionService: VolitionService,
    private readonly fb: FormBuilder
  ) {
    this.formGroup = this.buildRegistrationForm();
    this.updateFormState();
  }

  buildRegistrationForm(): FormGroup {
    return this.fb.group({
      donorType: [this.volition?.donorType, [Validators.required]],
      bodyParts: this.fb.array(
        this.bodyParts?.map(s => {
          const isInVolition = !!this.volition?.bodyPartIds?.find(
            selectedBodyPartId => selectedBodyPartId === s.id
          );
          return new FormGroup({
            id: new FormControl(s.id),
            translationKey: new FormControl(s.translationKey),
            isSelected: new FormControl(isInVolition)
          });
        }) ?? []
      )
    });
  }

  getBodyPartsArray(): FormGroup[] {
    return (this.formGroup.get('bodyParts') as FormArray)
      .controls as FormGroup[];
  }

  getBodyPartName(bodyPartFormGroup: FormGroup): string {
    return bodyPartFormGroup.get('translationKey')?.value;
  }

  ngOnInit(): void {
    this.formGroup = this.buildRegistrationForm();
    this.formGroup.valueChanges.subscribe(() => {
      this.updateFormState();
    });
    this.updateFormState();
  }

  updateFormState() {
    const bodyPartFormGroups: FormGroup[] = (
      this.formGroup.get('bodyParts') as FormArray
    ).controls as FormGroup[];

    if (this.formGroup.value.donorType !== 'PARTIAL_DONOR') {
      for (const bodyPartFormGroup of bodyPartFormGroups) {
        bodyPartFormGroup.get('isSelected')?.setValue(false, {
          emitEvent: false
        });
        bodyPartFormGroup.get('isSelected')?.disable({ emitEvent: false });
      }
    } else {
      for (const bodyPartFormGroup of bodyPartFormGroups) {
        bodyPartFormGroup.get('isSelected')?.enable({ emitEvent: false });
      }
    }

    this.showNoBodyPartsSelectedErrorMessage = false;
  }

  getBodyPartIds(): string[] {
    const bodyPartFormGroups: FormGroup[] = (
      this.formGroup.get('bodyParts') as FormArray
    ).controls as FormGroup[];
    return bodyPartFormGroups
      .map(formGroup => {
        if (formGroup.get('isSelected')?.value === true) {
          return formGroup.get('id')?.value as string;
        }
        return '';
      })
      .filter(bodyPartId => bodyPartId !== '');
  }

  buildNewVolition(): VolitionDto {
    return this.volitionService.buildNewVolition(
      this.volition,
      this.formGroup.get('donorType')?.value,
      this.getBodyPartIds()
    );
  }

  atLeastOneBodyPartSelected(): boolean {
    const bodyPartFormGroups: FormGroup[] = (
      this.formGroup.get('bodyParts') as FormArray
    ).controls as FormGroup[];
    return (
      bodyPartFormGroups?.filter(formGroup => formGroup.value.isSelected)
        .length > 0
    );
  }

  onSubmit() {
    if (
      this.formGroup.value.donorType === 'PARTIAL_DONOR' &&
      !this.atLeastOneBodyPartSelected()
    ) {
      this.showNoBodyPartsSelectedErrorMessage = true;
      return;
    }
    const newVolition: VolitionDto = this.buildNewVolition();
    if (this.formGroup.dirty) {
      this.volitionChange.emit(newVolition);
    }
  }
}
